"use client"
import { useDispatch, useSelector } from 'react-redux'
import { setUserToStore} from "../redux/slices/globalSlice";
import { useMemo } from 'react';

export const useAuth = () => {
    const {
        user,
        token,
    } = useSelector (state => state?.global)

    const dispatch = useDispatch()

    const getUser = () => {
        if (user !== null && user !== undefined) {
            return user
        }
        if (typeof window !== "undefined") {
            let res = JSON.parse(localStorage.getItem("user"));
            return res ? res : null;
        }
    };

    const getToken = () => {
        if (token !== null && token !== undefined) {
            return token
        }
        if (typeof window !== "undefined") {
            let res = localStorage.getItem("token");
            return res ? res : null;
        }
    }

    const getLanguage = () => {
        if (typeof window !== "undefined") {
            let res = localStorage.getItem("language");
            return res ? res : null;
        }
    }

    const removeToken = () => {
        if (typeof window !== "undefined") {
            localStorage.removeItem("token");
        }
    };

    const setToken = (token) => {
        if (typeof window !== "undefined") {
            localStorage.setItem("token", token);
        }
    }

    const removeUser = () => {
        dispatch(setUserToStore(null))
        if (typeof window !== "undefined") {
            sessionStorage.removeItem("user");
        }
    };

    const setUser = (user) => {
        dispatch(setUserToStore(user))
        if (typeof window !== "undefined") {
            sessionStorage.setItem("user", JSON.stringify(user));
        }
    };

    const logout = () => {
        removeToken();
        removeUser();
    };

    const isUserEmailVerified = useMemo(() => {
        return user?.isverified || user?.verification?.isverified
    }, [user])

    // get user from localStorage
    const loggedInUser = getUser();

    return { loggedInUser, setUser, removeUser, getToken, setToken, removeToken, getUser, isUserEmailVerified, logout};
}
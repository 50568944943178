    import * as React from 'react';
    import { BarChart } from '@mui/x-charts/BarChart';
    export default function SupplierChart ({xData, seriesData}){

        //get screen width
        const width = window.innerWidth;

        return (
            <BarChart
                xAxis={[{
                        scaleType: 'band',
                        data: xData,
                        barGapRatio: 1,
                    categoryGapRatio: 0.6,
                    }]}
                series={[{ data: seriesData }]}
                slotProps={{
                    bar: {
                        clipPath: `inset(0px round 10px 10px 0px 0px)`,
                    },
                }}
                width={width}
                height={150}
                colors={
                    [
                        'var(--green)',
                    ]
                }
            />
        );
}

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonApi } from "./commonApi";
import { BASE_URL, requestConfig } from "../../util";

export const merchantApi = commonApi.injectEndpoints({
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        ...requestConfig, // Include the requestConfig in the baseQuery
    }),
    endpoints: (builder) => ({
        getUnpaidBills: builder.query({
            query: (merchantId) => `merchants/${merchantId}/unpaid-bills`,
            providesTags: ['Merchant'],
        }),
        getTotalExpenses: builder.query({
            query: (merchantId) => `merchants/${merchantId}/total-order-expense`,
            providesTags: ['Merchant'],
        }),
        deleteSupplier: builder.mutation({
            query: (id) => ({
                url: `suppliers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Suppliers'],
        }),
        getMerchantProducts: builder.query({
            query: (merchantId) => `merchants/${merchantId}/products`,
            providesTags: ['Merchant'],
        }),
        getMerchantOrders: builder.query({
            query: (merchantId) => `merchants/${merchantId}/orders`,
            providesTags: ['Merchant', 'Orders'],
        }),
    }),
});

export const {
    useGetUnpaidBillsQuery,
    useGetTotalExpensesQuery,
    useGetMerchantProductsQuery,
    useGetMerchantOrdersQuery,
} = merchantApi;
import React, { useState } from 'react';
import Layout from "../../Layouts/Layout";

const styles = {
  helpContainer: {
    maxWidth: '800px',
    margin: '50px auto',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  },
  helpHeader: {
    textAlign: 'center',
    marginBottom: '30px'
  },
  helpHeaderH1: {
    fontSize: '2.5rem',
    margin: '0',
    color: '#4a4a4a'
  },
  helpItem: {
    borderBottom: '1px solid #ddd',
    padding: '15px 0'
  },
  helpQuestion: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  helpQuestionHover: {
    color: '#8c52ff'
  },
  helpQuestionActive: {
    transform: 'rotate(45deg)' // Rotate to make a plus sign a cross
  },
  helpAnswer: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-out',
	fontSize: '1rem',
    fontWeight: 'normal' // Removing bold effect
  },
  helpAnswerActive: {
    maxHeight: '200px' // Adjust as needed
  }
};

const questionsAndAnswers = [
  {
    question: "Comment faire un reset de mon mot de passe?",
    answer: "Appuyez sur l'icône du menu en haut à droite, puis sur 'Profil'. Saisissez votre mot de passe actuel, puis 2 fois le nouveau mot de passe."
  },
  {
    question: "Comment rajouter un fournisseur ou un produit?",
    answer: "Naviguez via les icônes du bas sur l'onglet fournisseur ou produit, il suffit ensuite d'appuyer sur le petit bouton '+' en mauve."
  },
  {
    question: "Est-il possible de modifier un fournisseur ou un produit?",
    answer: "Effectivement, il suffit de cliquer sur le fournisseur ou le produit en question, puis 'Modifier'. Une fois que vous aurez modifié les informations souhaitées, il suffit de sauvegarder."
  },
  {
    question: "Quelle est la différence entre 'Montant Commande' et 'Montant Facture'?",
    answer: "Le 'Montant Commande' est le montant de la commande que vous avez passé, basé sur le prix par article que vous avez défini. Le 'Montant Facture' est le montant réel de la facture."
  },
  {
    question: "Comment mon fournisseur peut m'envoyer sa facture?",
    answer: "Votre fournisseur peut vous envoyer sa facture en l'envoyant à invoicing.kiwimetrics@gmail.com Attention, il faut que le sujet de l'email soit similaire à ceci : 'Facture - KM000073 - Total : 102.5 €'."
  },
  {
    question: "Comment supprimer une commande?",
    answer: "Il suffit de naviguer dans via les icônes du bas sur l'onglet 'Commandes', puis de cliquer sur le numéro de la commande en question. Puis de supprimer."
  },
  {
    question: "Comment contacter le support?",
    answer: "Vous pouvez envoyer un email à support@kiwimetrics.io"
  }
];

const Help = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleHelp = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div style={styles.helpContainer}>
      <div style={styles.helpHeader}>
        <h1 style={styles.helpHeaderH1}>Help</h1>
      </div>
      {questionsAndAnswers.map((item, index) => (
        <div key={index} style={styles.helpItem}>
          <div
            style={{ ...styles.helpQuestion, ...(activeIndex === index ? styles.helpQuestionHover : {}) }}
            onClick={() => toggleHelp(index)}
          >
            {item.question}
            <span style={activeIndex === index ? styles.helpQuestionActive : {}}>{activeIndex === index ? '−' : '+'}</span>
          </div>
          <div style={{ ...styles.helpAnswer, ...(activeIndex === index ? styles.helpAnswerActive : {}) }}>
            <p style={{ fontSize: '1rem', fontWeight: 'normal' }}>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

Help.getLayout = (page) => <Layout text="help">{page}</Layout>;

export default Help;

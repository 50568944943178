import SupplierChart from "./SupplierChart";
import {currentMonth, currentQuarter, currentYear, expensesPerSupplierText} from "../../Constants/translations";
import {useGetExpensesPerSupplierQuery} from "../../redux/apiServices/productsApi";
import {useEffect, useState} from "react";

export const ExpensesPerSupplier = () => {
    const language = localStorage.getItem('language');
    const {data:expensesPerSupplier, isLoading} = useGetExpensesPerSupplierQuery(sessionStorage.getItem('merchant'));

    const [suppliers, setSuppliers] = useState([]);
    const [monthExpenses, setMonthExpenses] = useState([0,0,0]);
    const [quarterExpenses, setQuarterExpenses] = useState([0,0,0]);
    const [yearExpenses, setYearExpenses] = useState([0,0,0]);

    useEffect(() => {
        if(isLoading) return;
        setSuppliers(expensesPerSupplier?.currentMonthExpenses?.map((expense) => {
            return expense.supplier.name || 'Unknown'}));

        setMonthExpenses(expensesPerSupplier?.currentMonthExpenses?.map((expense) => {
            return expense.total._sum?.billAmount || 0}));

        setQuarterExpenses(expensesPerSupplier?.currentQuarterExpenses?.map((expense) => {
            return expense.total._sum?.billAmount || 0}));

        setYearExpenses(expensesPerSupplier?.currentYearExpenses?.map((expense) => {
            return expense.total._sum?.billAmount || 0}));

        }, [expensesPerSupplier]);

    return (
        <div className="expenses-per-supplier-container">
            <div className="main-title">
                {expensesPerSupplierText[language]}: {currentMonth[language]}
            </div>
            {(!isLoading && monthExpenses) ? <SupplierChart xData={suppliers ? suppliers : ['Supplier 1', "Supplier 2", "Supplier 3"]}
                           seriesData={monthExpenses ? monthExpenses : [4, 8, 16]}
            />
            : <div className="skeleton-container">
                   <div className="skeleton-chart">
                       Loading...
                   </div>
            </div>}

            <div className="main-title">
                {expensesPerSupplierText[language]}: {currentQuarter[language]}
            </div>
            {(!isLoading && quarterExpenses) ? <SupplierChart xData={suppliers ? suppliers : ['Supplier 1', "Supplier 2", "Supplier 3"]}
                           seriesData={quarterExpenses ? quarterExpenses : [4, 8, 16]}/>
            : <div className="skeleton-container">
                <div className="skeleton-chart">
                    Loading...
                </div>
            </div>}

            <div className="main-title">
                {expensesPerSupplierText[language]}: {currentYear[language]}
            </div>
            {(!isLoading && yearExpenses) ? <SupplierChart xData={suppliers ? suppliers : ['Supplier 1', "Supplier 2", "Supplier 3"]}
                           seriesData={yearExpenses ? yearExpenses : [4, 8, 16]}/>
            : <div className="skeleton-container">
                <div className="skeleton-chart">
                    Loading...
                </div>
            </div>}
        </div>
    );
}
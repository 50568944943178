export const euro = "€";


// LOGIN
// french translation
export const welcome = ["welcome", "bienvenue"];
export const username = ["username", "email"];
export const passwordText = ["password", "mot de passe"];
export const newPasswordText = ["new password", "nouveau mot de passe"];
export const confirmPasswordText = ["confirm password", "confirmer le mot de passe"];
export const signIn = ["sign in", "se connecter"];
export const signOut = ["sign out", "déconnexion"];
export const profile = ["profile", "profil"];
export const help = ["help", "aide"];
export const loading = ["Loading...", "Chargement..."];
export const submit = ["submit", "soumettre"];

// DASHBOARD
export const dashboard = ["dashboard", "dashboard"];
export const totalUnpaidBillsText = ["total unpaid bills", "total des factures impayées"];
export const totalOrderExpensesText = ["total order expenses", "total des dépenses"];
export const expensesPerSupplierText = ["expenses per supplier", "dépenses par fournisseur"];

export const currentMonth = ["current month", "ce mois-ci"];
export const currentQuarter = ["current quarter", "ce trimestre"];
export const currentYear = ["current year", "cette année"];

// SUPPLIERS

export const suppliers = ["suppliers", "fournisseurs"];

export const selectSupplier = ["select supplier", "sélectionner un fournisseur"];

export const addASupplier = ["add a supplier", "ajouter un fournisseur"];

export const enterTheEmail = ["enter the email", "entrez l'email"];

export const enterPhone= ["enter phone +324xxxxxxxx", "entrez le téléphone +324xxxxxxxx"];

export const phoneText = ["phone", "Téléphone"];
// PRODUCTS
export const products = ["products", "produits"];

export const addAProduct = ["add a product", "ajouter un produit"];

export const enterName = ["enter name", "entrez le nom"];

export const unitPrice = ["unit price", "Prix unitaire"];

export const save = ["save", "sauvegarder"];

export const edit = ["edit", "modifier"];
export const cancel = ["cancel", "annuler"];

export const deleteText = ["delete", "supprimer"];

export const nameText = ["name", "Nom"];

export const emailText = ["email", "Email"];

export const download = ["download", "télécharger"];

export const whatsappText = ["whatsapp", "WhatsApp"];
//ORDERS
export const placeOrder = ["place order", "passer commande"];

export const placeOrderText = ["place order", "passer commande"];


export const filters = ["filters", "filtres"];

export const bills  = ["bills", "factures"];

export const paid = ["paid", "Payé"];

export const orderId = ["Order ID", "ID"];

export const date = ["Date", "Date"];

export const supplier = ["Supplier", "Fournisseur"];

export const bill = ["Bill", "Facture"];

export const amount = ["Amount", "Montant"];

export const orderAmountLang = ["Order Amount", "Montant commande"];

export const billAmountLang = ["Bill Amount", "Montant facture"];

export const sendBills = ["Send Bills", "Envoyer des factures"];

export const order = ["order", "commande"];

export const orderDetails = ["order details", "détails de la commande"];

export const orderDate = ["order date", "date de commande"];

export const orderSupplier = ["order supplier", "fournisseur de commande"];

export const orderBill = ["order bill", "facture de commande"];

export const passwordResestMessage = ["Password reset successfully!", "Réinitialisation du mot de passe réussie!"];

export const passwordNotMatched = ["Password not matched", "Mot de passe ne correspond pas"];
//GLOBAL
export const search = ["search", "chercher"];

export const start = ["start", "démarrer"];

export const end = ["end", "fin"];

export const choose = ["choose", "envoyer par"];

export const invalidEmailOrPassword = ["Invalid email or password", "Email ou mot de passe invalide"];

export const invalidEmail = ["Invalid email", "Email invalide"];

export const invalidPhone = ["Invalid phone", "Téléphone invalide"];

export const deleteSupplierConfirmation = ["Are you sure you want to delete this supplier?", "Voulez-vous vraiment supprimer ce fournisseur?"];

export const deleteProductConfirmation = ["Are you sure you want to delete this product?", "Voulez-vous vraiment supprimer ce produit?"];

export const deleteOrderConfirmation = ["Are you sure you want to delete this order?", "Voulez-vous vraiment supprimer cette commande?"];

export const deleteBillConfirmation = ["Are you sure you want to delete this bill?", "Voulez-vous vraiment supprimer cette facture?"];

export const deleteConfirmationMessage = ["Are you sure you want to delete this?", "Voulez-vous vraiment supprimer ceci?"];

export const logoutConfirmation = ["Are you sure you want to logout?", "Voulez-vous vraiment vous déconnecter?"];

export const deletedSuccessfully = ["Deleted successfully!", "Supprimé avec succès!"];

export const allFieldsRequired = ["All fields are required", "Tous les champs sont obligatoires"];

export const alreadyExist = ["Already exist", "Existe déjà"];

export const getPlaceOrderSubject = (merchantName, orderId) => {
    // in french
    return `Commande de : ${merchantName} - numéro de commande : ${orderId}`;
}

export const getPlaceOrderText = (merchantName, orderId, orderItemsText) => {
    // const body = `Hello, Please find below the order for the order number ${uniqueOrderId}\n\n${orderItemsText}\n\nReminder : To send us your bill, please send it to “invoicing.kiwimetrics@gmail.com” with the subject “Bill - Order ${uniqueOrderId} - Total Amount : ${totalAmount} €”`
    // in french
    let body = `Bonjour, Veuillez trouver ci-dessous la commande pour le numéro de commande ${orderId}\n\n ${orderItemsText} Rappel : Pour nous envoyer votre facture, veuillez nous l'envoyer à " ` + merchantName + ` " avec le sujet "Facture - Commande ${orderId} - Montant total : ${euro}"`;
    return body;
}
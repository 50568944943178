import './Products.css'
import { useEffect, useState } from "react";
import Layout from "../../Layouts/Layout";
import { useGetSuppliersQuery } from "../../redux/apiServices/suppliersApi";
import {
    useCreateProductMutation,
    useDeleteProductMutation,
    useEditProductMutation,
    useGetProductsQuery
} from "../../redux/apiServices/productsApi";
import {
    euro,
    search,
    addAProduct,
    enterName,
    unitPrice,
    save,
    edit,
    deleteText,
    nameText,
    emailText,
    whatsappText,
    cancel,
    placeOrderText,
    deleteConfirmationMessage,
    choose,
    getPlaceOrderSubject
} from "../../Constants/translations";
import { usePlaceOrderMutation } from "../../redux/apiServices/orderApi";
import ScreenLayout from '../../Layouts/ScreenLayout';

const Products = () => {
    let merchant = sessionStorage.getItem('merchant');
    const { data: suppliersData, isLoading: suppliersLoading, error } = useGetSuppliersQuery({ merchant: merchant, limit: 10, page: 1 }, {
        skip: !merchant
    });
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const { data: productsData, isLoading: productsLoading, error: productsError } = useGetProductsQuery(selectedSupplier, {
        skip: !selectedSupplier
    });
    const user = JSON.parse(sessionStorage.getItem('user'));
    const [placeOrderMutate] = usePlaceOrderMutation();
    const [createProduct] = useCreateProductMutation();
    const [deleteProduct] = useDeleteProductMutation();
    const [editProductMutate] = useEditProductMutation();
    const [addProduct, setAddProduct] = useState(false);
    const [editProduct, setEditProduct] = useState(null);
    const [placeOrder, setPlaceOrder] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const language = localStorage.getItem('language');
    const [orderItems, setOrderItems] = useState([]);
    const [errorText, setErrorText] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [editingProduct, setEditingProduct] = useState(false);

    const [addProductData, setAddProductData] = useState({
        name: '',
        unitPrice: 0,
        supplierId: selectedSupplier
    });

    const [editProductData, setEditProductData] = useState({
        name: '',
        unitPrice: 0,
        supplierId: ''
    });

    useEffect(() => {
        setAddProductData({
            ...addProductData,
            supplierId: selectedSupplier
        })
    }, [selectedSupplier])


    const handleAddProductDataChange = (e) => {
        const { name, value } = e.target;

        if (name === 'unitPrice') {
            if (value < 0 || value === '') {
                setErrorText('Unit price cannot be negative or empty');
            } else {
                setErrorText('');
            }
        }

        setAddProductData({
            ...addProductData,
            [name]: value,
        });
    }

    const handleEditProductDataChange = (e) => {
        const { name, value } = e.target;

        if (name === 'unitPrice') {
            if (value < 0 || value === '') {
                setErrorText('Unit price cannot be negative or empty');
            } else {
                setErrorText('');
            }
        }

        setEditProductData({
            ...editProductData,
            [name]: value,
        });
    }

    const handleCreateProduct = () => {
        if (addProductData.name === '') {
            setErrorText('Veuillez remplir le nom');
            return;
        }
        if (addProductData.unitPrice < 0 || addProductData.unitPrice === '' || addProductData.unitPrice === 0 || addProductData.unitPrice?.trim() === '') {
            // set price 0
            setAddProductData({
                ...addProductData,
                unitPrice: 0,
            });
        }

        setErrorText('');

        createProduct(addProductData);
        setAddProductData({
            name: '',
            unitPrice: 0,
            supplierId: selectedSupplier
        });
        setAddProduct(false);
    }

    const handleEditProduct = () => {
        if (editProductData.name === '') {
            setErrorText('Veuillez remplir le nom');
            return;
        }

        if (editProductData.unitPrice < 0 || editProductData.unitPrice === '') {
            setErrorText('Unit price cannot be negative or empty');
            return;
        }

        setErrorText('');
        editProductMutate({
            data: editProductData,
            productId: editProduct.id
        });
        setEditProductData({
            name: '',
            unitPrice: 0,
            supplierId: ''
        });
        setEditProduct(null);
    }

    const filteredProducts = productsData?.products?.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePlaceOrder = async (method) => {
        const order = {
            userId: user.id,
            supplierId: selectedSupplier,
            orderItems: orderItems,
        };

        const placeOrderResponse = await placeOrderMutate(order);

        if (!placeOrderResponse?.data?.order) {
            return;
        }

        const supplierEmail = suppliersData?.suppliers?.find(supplier => supplier.id === placeOrderResponse?.data?.order?.supplierId)?.email;
        const supplierPhone = suppliersData?.suppliers?.find(supplier => supplier.id === placeOrderResponse?.data?.order?.supplierId)?.phone;
        const merchantName = JSON.parse(sessionStorage.getItem('user'))?.businessName; // Replace with your actual business name
        const uniqueOrderId = placeOrderResponse?.data?.order?.orderId; // Replace with your actual unique order ID
        const totalAmount = orderItems.reduce((acc, item) => {
            const product = productsData?.products?.find(product => product.id === item.productId);
            return acc + (product?.unitPrice * item.quantity || 0);
        }, 0);

        const orderItemsText = orderItems.map(item => {
            const product = productsData?.products?.find(product => product.id === item.productId);
            return `${item.quantity} x ${product?.name}`;
        }).join('\n');

        if (method === "email") {
            // const subject = `Commande de ${merchantName} | Numero de commande : ${uniqueOrderId}`;
            const subject = getPlaceOrderSubject(merchantName, uniqueOrderId)
            const body = `Bonjour,\n\nVeuillez trouver ci-dessous la commande ayant le numéro ${uniqueOrderId}\n\n${orderItemsText}\n\nRappel : Pour nous envoyer votre facture, envoyez un email à l'adresse “invoicing.kiwimetrics@gmail.com” avec le sujet .“Facture - ${uniqueOrderId} - Total : ${totalAmount} €”\n\nVous pouvez adapter le montant total si celui-ci ne correspond pas au montant de votre facture.\n\nMerci.`;
            const mailtoLink = `mailto:${supplierEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
        } else if (method === "whatsapp") {
            const message = `Bonjour,%20%0A%0A*Commande%20de*:%20${merchantName}%0A*Num%C3%A9ro%20de%20commande*:%20${uniqueOrderId}%0A%0A*Commande*:%0A${orderItemsText.replace(/\n/g, '%0A')}%0A%0A*Rappel*:%20Pour%20nous%20envoyer%20votre%20facture,%20envoyez%20un%20email%20%C3%A0%20l'adresse%20*%22invoicing.kiwimetrics@gmail.com%22*%20avec%20le%20sujet%20*%22Facture%20-%20${uniqueOrderId}%20-%20Total%20:%20${totalAmount}%20%E2%82%AC%E2%80%9D%22*.%0A%0AVous%20pouvez%20adapter%20le%20montant%20total%20si%20celui-ci%20ne%20correspond%20pas%20au%20montant%20de%20votre%20facture.%0A%0AMerci.`;

            const whatsappLink = `https://wa.me/${supplierPhone}?text=${message}`;
            window.location.href = whatsappLink;
        }

        setOrderItems([]);
    };

    const handleQuantityChange = (productId, quantity) => {
        setOrderItems(prevOrderItems => {
            // Check if the product already exists in the order items
            const existingItemIndex = prevOrderItems.findIndex(item => item.productId === productId);

            // If the product exists, update its quantity
            if (existingItemIndex !== -1) {
                const updatedOrderItems = prevOrderItems.map((item, index) => {
                    if (index === existingItemIndex) {
                        return {
                            ...item,
                            quantity: Math.max(0, quantity) // Ensure quantity is non-negative
                        };
                    }
                    return item;
                });

                // Remove items with quantity 0
                return updatedOrderItems.filter(item => item.quantity > 0);
            } else {
                // If the product doesn't exist, add it to the order items if quantity is greater than 0
                if (quantity > 0) {
                    return [...prevOrderItems, { productId, quantity }];
                } else {
                    return prevOrderItems;
                }
            }
        });
    };



    const handleDeleteProduct = () => {
        deleteProduct(editProduct.id);
        setEditProduct(null);
    }

    useEffect(() => {
        if (suppliersData?.suppliers?.length > 0) {
            setSelectedSupplier(suppliersData.suppliers[0].id);
        }
    }, [suppliersData]);

    const calculateProductPrice = (productId, quantity) => {
        const product = productsData?.products?.find(product => product.id === productId);
        return (product?.unitPrice || 0) * quantity;
    };

    return (
        <div className="suppliers-container">
            <div className="products-suppliers-select">
                {
                    suppliersData?.suppliers?.map((supplier, index) => (
                        <button className={`product-supplier ${selectedSupplier === supplier.id && 'selected-supplier'}`} onClick={
                            () => {
                                setSelectedSupplier(supplier.id);
                                setOrderItems([]);
                            }
                        }
                            key={index}>
                            {supplier?.name}
                        </button>
                    ))
                }
            </div>
            <div className="products-search">
                <input type="text" placeholder={search[language]} className="input" value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)} />
                {(user?.role === 'owner' || user?.role === 'editor') && <button onClick={
                    () => setAddProduct(!addProduct)
                } className="add-supplier-button">
                    <i className="fa-solid fa-add" />
                </button>
                }
            </div>
            <div className="total-amount">
                Total: {orderItems.reduce((acc, item) => acc + calculateProductPrice(item.productId, item.quantity), 0).toFixed(1)} {euro}
            </div>
            <ScreenLayout>
                <div className="products-list">
                    {
                        !productsLoading ? filteredProducts?.map((product, index) => {
                            const productQuantity = orderItems.find(item => item.productId === product.id)?.quantity || 0;
                            return (
                                <div key={index} className="product">
                                    <div className="product-name" onClick={
                                        () => setEditProduct(product)
                                    }>
                                        {product?.name}
                                    </div>
                                    <div className="product-actions">
                                        <button className="product-action product-remove" onClick={
                                            () => handleQuantityChange(product.id, productQuantity - 1)
                                        }>
                                            <i className="fa-solid fa-minus" />
                                        </button>
                                        <div className="product-quantity">
                                            {productQuantity}
                                        </div>
                                        <button className="product-action product-add" onClick={
                                            () => handleQuantityChange(product.id, productQuantity + 1)
                                        }>
                                            <i className="fa-solid fa-plus" />
                                        </button>
                                    </div>
                                    <div className="product-price">
                                        {calculateProductPrice(product.id, productQuantity)?.toFixed(1) || '...'} {euro}
                                    </div>
                                </div>
                            );
                        }) :
                            <div className="skeleton-container">
                                <div className="skeleton-list">
                                    Loading...
                                </div>
                                <div className="skeleton-list">
                                    Loading...
                                </div>
                                <div className="skeleton-list">
                                    Loading...
                                </div>
                            </div>
                    }
                </div>

            </ScreenLayout>
            <div className="place-order-button-div">
                <button className="place-order-button" onClick={
                    () => setPlaceOrder(true)
                }>
                    <div />
                    <div>{placeOrderText[language]} </div>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
            {
                addProduct && (
                    <div className="add-supplier-form">
                        <div className="add-supplier-top">

                            <h3 className="add-supplier-heading">
                                {addAProduct[language]}
                            </h3>
                            <button onClick={
                                () => setAddProduct(false)
                            } className="icon-button">
                                <i className="fa-solid fa-x fa-2x fa-2x" />
                            </button>

                        </div>
                        <div className="supplier-input-div">
                            <span className="error-text">
                                {errorText}
                            </span>
                            <input type="text" className="supplier-input" name="name" placeholder={enterName[language]} onChange={
                                handleAddProductDataChange
                            } />
                            <input type="number" className="supplier-input" name="unitPrice" placeholder={unitPrice[language]} onChange={
                                handleAddProductDataChange
                            } />
                        </div>
                        <button className="supplier-save-button" onClick={
                            handleCreateProduct
                        }>
                            <div />
                            <div>{save[language]}</div>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                )

            }
            {
                editProduct && (
                    <div className="add-supplier-form">
                        <div className="add-supplier-top">
                            {/* {(user?.role === 'owner' || user?.role === 'editor') && <button className="supplier-top-button" onClick={
                                () => {
                                    setEditingProduct(false);
                                    setEditProductData(editProduct);
                                }
                            }>
                                {editingProduct ? cancel[language] : edit[language]}
                            </button>} */}
                            {
                                (user?.role === 'owner' || user?.role === 'editor') &&
                                    editingProduct ?
                                    <button className="supplier-top-button" onClick={
                                        () => {
                                            setEditingProduct(false);
                                            setEditProductData(null);
                                        }
                                    }> {cancel[language]} </button>

                                    : <button className="supplier-top-button" onClick={
                                        () => {
                                            setEditingProduct(true);
                                            setEditProductData(editProduct);
                                        }
                                    }>
                                        {edit[language]}
                                    </button>
                            }
                            {(user?.role === 'owner' || user?.role === 'editor') && <button className="supplier-top-button" onClick={
                                () => setDeleteConfirmation(true)
                            }>
                                {deleteText[language]}
                            </button>}
                            {
                                deleteConfirmation && (
                                    <div className="delete-confirmation">
                                        <span class="supplier-field">
                                            {deleteConfirmationMessage[language]}
                                        </span>
                                        <button onClick={
                                            handleDeleteProduct
                                        } className="no">
                                            {"Oui"}
                                        </button>
                                        <button onClick={
                                            () => setDeleteConfirmation(false)
                                        } className="yes">
                                            {"Non"}
                                        </button>
                                    </div>
                                )
                            }
                            <button onClick={
                                () => {
                                    setEditingProduct(false);
                                    setEditProduct(null)
                                }
                            } className="icon-button">
                                <i className="fa-solid fa-x fa-2x" />
                            </button>

                        </div>
                        {!editingProduct ? <div className="supplier-input-div">
                            <span className="supplier-field">
                                {nameText[language]}: {editProduct?.name}
                            </span>
                            <span className="supplier-field">
                                {unitPrice[language]}: {editProduct?.unitPrice} {euro}
                            </span>
                        </div> :
                            <div className="supplier-input-div">
                                <span className="error-text">
                                    {errorText}
                                </span>
                                <input type="text" className="supplier-input" name="name" placeholder={enterName[language]} value={editProductData.name} onChange={
                                    handleEditProductDataChange
                                } />
                                <input type="number" className="supplier-input" name="unitPrice" placeholder={unitPrice[language]} value={editProductData.unitPrice} onChange={
                                    handleEditProductDataChange
                                } />
                                <button className="supplier-save-button" onClick={
                                    handleEditProduct
                                }>
                                    <div />
                                    <div>{save[language]}</div>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>}
                    </div>
                )
            }
            {
                placeOrder && (
                    <div className="add-supplier-form">
                        <div className="add-supplier-top">

                            <h3 className="add-supplier-heading">
                                {choose[language]}
                            </h3>
                            <button onClick={
                                () => setPlaceOrder(false)
                            } className="icon-button">
                                <i className="fa-solid fa-x fa-2x" />
                            </button>
                        </div>
                        <div className="choose-div">
                            <div className="choose-ind">
                                <button className="choose-button mail" onClick={
                                    () => handlePlaceOrder("email")
                                }>
                                    <i className="fa-solid fa-envelope"></i>
                                </button>
                                {emailText[language]}
                            </div>

                            <div className="choose-ind">
                                <button className="choose-button phone" onClick={
                                    () => handlePlaceOrder("whatsapp")
                                }>
                                    <i className="fa-solid fa-phone"></i>
                                </button>
                                {whatsappText[language]}
                            </div>

                        </div>
                    </div>
                )
            }
        </div>
    );
}

Products.getLayout = (page) => <Layout text="products">{page}</Layout>

export default Products;


import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonApi } from './commonApi';
import {BASE_URL, requestConfig} from "../../util";

export const userApi = commonApi.injectEndpoints({
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        ...requestConfig, // Include the requestConfig in the baseQuery
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: `users/login`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Users'],
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: `users/user/reset-password`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Users'],
        }),
    }),
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
} = userApi;
import './TotalOrderExpenses.css'
import {currentMonth, currentQuarter, currentYear, euro, totalOrderExpensesText} from "../../Constants/translations";
import {useGetTotalExpensesQuery, useGetUnpaidBillsQuery} from "../../redux/apiServices/merchantApi";
import {formatCashValue} from "../../util";
export const TotalOrderExpenses = () => {
    const language = localStorage.getItem('language');
    const {data:totalExpenses, isLoading} = useGetTotalExpensesQuery(sessionStorage.getItem('merchant'));
    return (
        <div className="total-order-expenses-container">
            <h3 className="main-title">{totalOrderExpensesText[language]}</h3>
            <div className="total-unpaid-bills">
                <div className="amount-component">
                    <div className="unpaid-bill-title">
                        {currentMonth[language]}
                    </div>
                    <div className="amount">
                        {euro}
                        {
                            isLoading ? '...' : formatCashValue(totalExpenses?.monthly?._sum?.billAmount || 0)
                        }
                    </div>
                </div>

                <div className="amount-component">
                    <div className="unpaid-bill-title">
                        {currentQuarter[language]}
                    </div>
                    <div className="amount">
                        {euro}{
                        isLoading ? '...' : formatCashValue(totalExpenses?.quarterly?._sum?.billAmount || 0)
                    }
                    </div>
                </div>

                <div className="amount-component">
                    <div className="unpaid-bill-title">
                        {currentYear[language]}
                    </div>
                    <div className="amount">
                        {euro}{
                        isLoading ? '...' : formatCashValue(totalExpenses?.yearly?._sum?.billAmount || 0)
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}
import './Dashboard.css'
import {TotalUnpaidBills} from "../../Components/Dashboard/TotalUnpaidBills";
import {TotalOrderExpenses} from "../../Components/Dashboard/TotalOrderExpenses";
import {ExpensesPerSupplier} from "../../Components/Dashboard/ExpensesPerSupplier";
import Layout from "../../Layouts/Layout";
import {useSelector} from "react-redux";

const Dashboard = () => {
    return (<div className="dashboard-container">
        <TotalUnpaidBills/>
        <TotalOrderExpenses/>
        <ExpensesPerSupplier/>
    </div>
)};

Dashboard.getLayout = (page) =>
    <Layout text="dashboard">
        {page}
    </Layout>

export default Dashboard;
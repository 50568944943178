import React, { useState } from 'react';
import './Profile.css';
import Layout from "../../Layouts/Layout";
import { confirmPasswordText, emailText, loading, nameText, newPasswordText, passwordNotMatched, passwordResestMessage, passwordText, submit } from "../../Constants/translations";
import { useResetPasswordMutation } from '../../redux/apiServices/userApi';

const Profile = () => {
    const [name, setName] = useState(JSON.parse(sessionStorage.getItem('user')).username);
    const [email, setEmail] = useState(JSON.parse(sessionStorage.getItem('user')).email);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const language = localStorage.getItem('language');

    const [reset, { isLoading, isSuccess, error }] = useResetPasswordMutation();

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    }

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert(passwordNotMatched[language]);
            return;
        }

        reset({
            email: email,
            password: password,
            newPassword: newPassword
        })
        // Here you can call the API to update the user's profile
    }

    return (
        <div className="profile-container">
            {error && <div className="error">{error.data.message}</div>}
            {isSuccess && <div className="success">{passwordResestMessage[language]}</div>}
            <form onSubmit={handleSubmit}>
                <label>
                    {nameText[language]}:
                    <input type="text" value={name} onChange={handleNameChange} readOnly />
                </label>
                <label>
                    {emailText[language]}:
                    <input type="email" value={email} onChange={handleEmailChange} readOnly />
                </label>
                <label>
                    {passwordText[language]}:
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </label>
                <label>
                    {newPasswordText[language]}:
                    <input type="password" value={newPassword} onChange={handleNewPasswordChange} />
                </label>
                <label>
                    {confirmPasswordText[language]}:
                    <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                </label>
                <input type="submit" value={isLoading ?
                    loading[language] : submit[language]
                } disabled={isLoading} />
            </form>
        </div>
    );
}
Profile.getLayout = (page) => <Layout text="profile">{page}</Layout>
export default Profile;
import React from 'react'
import './Layout.css'
const ScreenLayout = (
    { children }
) => {
  return (
    <div className='screen-layout'>
        {children}
    </div>
  )
}

export default ScreenLayout

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonApi } from './commonApi';
import {BASE_URL, requestConfig} from "../../util";

export const productsApi = commonApi.injectEndpoints({
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        ...requestConfig, // Include the requestConfig in the baseQuery
    }),
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (supplierId) => `suppliers/${supplierId}/products`,
            providesTags: ['Products'],
        }),
        createProduct: builder.mutation({
            query: (data) => ({
                url: `products`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Products'],
        }),
        getExpensesPerSupplier: builder.query({
            query: (merchantId) => `merchants/${merchantId}/total-order-expense-by-supplier`,
            providesTags: ['Merchant'],
        }),
        deleteProduct: builder.mutation({
            query: (productId) => ({
                url: `products/${productId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Products'],
        }),
        editProduct: builder.mutation({
            query: ({
                data,
                productId
            }) => ({
                url: `products/${productId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Products'],
        }),
    }),
});

export const {
    useGetProductsQuery,
    useCreateProductMutation,
    useGetExpensesPerSupplierQuery,
    useDeleteProductMutation,
    useEditProductMutation,
} = productsApi;
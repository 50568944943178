import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {Login} from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Suppliers from "./pages/Suppliers/Suppliers";
import Products from "./pages/Products/Products";
import Orders from "./pages/Orders/Orders";
import {Provider} from "react-redux";
import Profile from "./pages/Profile/Profile";
import Help from "./pages/Help/Help";
import store from "./redux/store";

function App() {
    const merchant = sessionStorage.getItem('merchant');
    const user = JSON.parse(sessionStorage.getItem('user'));

    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/help" element={Help.getLayout(<Help/>)} />
                    {merchant && (
                        <>
                            {user.role === 'owner' && (
                                <>
                                    <Route path="/profile" element={Profile.getLayout(<Profile/>)} />
                                    <Route path="/dashboard" element={Dashboard.getLayout(<Dashboard/>)} />
                                    <Route path="/suppliers" element={Suppliers.getLayout(<Suppliers/>)} />
                                    <Route path="/products" element={Products.getLayout(<Products/>)} />
                                    <Route path="/orders" element={Orders.getLayout(<Orders/>)} />
                                    <Route path="/login" element={<Login/>} />
                                </>
                            )}
                            {(user.role === 'editor' || user.role === 'reader') && (
                                <>
                                    <Route path="/profile" element={Profile.getLayout(<Profile/>)} />
                                    <Route path="/login" element={<Login/>} />
                                    <Route path="/" element={Suppliers.getLayout(<Suppliers/>)} />
                                    <Route path="/products" element={Products.getLayout(<Products/>)} />
                                </>
                            )}
                        </>
                    )}
                    {!merchant && <Route path="/" element={<Login/>} />}
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;
import {TopBar} from "../Components/Common/TopBar";
import {BottomBar} from "../Components/Common/BottomBar";
import './Layout.css';
const Layout = ({ children, text }) => {
    return (
        <div className="main-layout">
            <TopBar heading={text}/>
            {children}
            <BottomBar/>
        </div>
    );
}

export default Layout;
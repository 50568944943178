import './Suppliers.css'
import { useEffect, useState } from "react";
import {
    useGetSuppliersQuery,
    useDeleteSupplierMutation,
    useCreateSupplierMutation,
    useEditSupplierMutation
} from "../../redux/apiServices/suppliersApi";
import Layout from "../../Layouts/Layout";
import {
    addASupplier,
    allFieldsRequired,
    alreadyExist,
    deleteSupplierConfirmation,
    deleteText,
    emailText,
    enterName,
    enterPhone,
    enterTheEmail, invalidEmail, invalidPhone, loading, nameText, phoneText,
    save,
    search
} from "../../Constants/translations";
import ScreenLayout from '../../Layouts/ScreenLayout';
const Suppliers = () => {
    const { data: suppliersData, isLoading: suppliersLoading, error: suppliersDataError, refetch } = useGetSuppliersQuery({ merchant: sessionStorage.getItem('merchant'), limit: 10, page: 1 });
    const user = JSON.parse(sessionStorage.getItem('user'));
    const [deleteSupplier, { isLoading: deleteSupplierLoading, isError, isSuccess }] = useDeleteSupplierMutation();
    const [createSupplier, { isLoading: createSupplierLoading }] = useCreateSupplierMutation();
    const [editSupplierMutate, { isLoading: editSupplierLoading }] = useEditSupplierMutation();
    const [searchTerm, setSearchTerm] = useState('');
    const language = localStorage.getItem('language');
    const [errorText, setErrorText] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [edit, setEdit] = useState(false);
    const [addSupplier, setAddSupplier] = useState(false);
    const [editSupplier, setEditSupplier] = useState(null);
    const [addSupplierData, setAddSupplierData] = useState({
        name: '',
        email: '',
        phone: '',
        merchant: sessionStorage.getItem('merchant')
    });
    const [editSupplierData, setEditSupplierData] = useState({
        name: '',
        email: '',
        phone: '',
        id: '',
        merchant: sessionStorage.getItem('merchant')
    });

    useEffect(() => {
        if (!deleteSupplierLoading && isError) {
            alert('Vous ne pouvez pas supprimer un fournisseur qui a des commandes')
            setDeleteConfirmation(false)
        } else if (!deleteSupplierLoading && isSuccess) {
            setEditSupplier(null);
        }
    }, [isError, deleteSupplierLoading, isSuccess]);

    const handleAddSupplierDataChange = (e) => {
        setAddSupplierData({
            ...addSupplierData,
            [e.target.name]: e.target.value,
        });
    }

    const handleEditSupplierDataChange = (e) => {
        setEditSupplierData({
            ...editSupplierData,
            [e.target.name]: e.target.value,
        });
    }

    const validateFields = (data) => {
        console.log(data)
        if (data.name === '' || data.email === '' || data.phone === '') {
            setErrorText(allFieldsRequired[1]);
            return false;
        }
        if (!data.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            setErrorText(invalidEmail[1]);
            return false;
        }
        // phone should always start with +324
        if (data.phone.length !== 12 || data.phone.slice(0, 4) !== '+324') {
            setErrorText(invalidPhone[1])
            return false;
        }
        return true;
    }

    const handleCreateSupplier = () => {
        if (!validateFields(addSupplierData)) {
            return
        }

        // check if supplier already exists
        // if (suppliersData?.suppliers?.find(supplier => supplier.email === addSupplierData.email) || suppliersData?.suppliers?.find(supplier => supplier.phone === addSupplierData.phone)) {
        //     setErrorText(alreadyExist[1])
        //     return;
        // }

        setErrorText('');
        createSupplier(addSupplierData);
        setAddSupplierData({
            name: '',
            email: '',
            phone: '',
            merchant: sessionStorage.getItem('merchant')
        })
        setAddSupplier(false);
    }

    const handleDeleteSupplier = () => {
        deleteSupplier(editSupplier.id);
        // setEditSupplier(null);
    }

    const handleEditSupplier = () => {
        if (!validateFields(editSupplierData)) {
            return
        }
        setErrorText('');
        editSupplierMutate(editSupplierData).then(() => {
            setEditSupplier(null);
            setEdit(false);
            refetch();
        });
    }

    const filteredSuppliers = suppliersData?.suppliers?.filter(supplier =>
        supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="suppliers-container">
            <div className="suppliers-search">
                <input type="text" placeholder={search[language]} className="input" value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)} />
                {(user?.role === "owner" || user?.role === 'editor') && <button onClick={
                    () => setAddSupplier(!addSupplier)
                } className="add-supplier-button">
                    <i className="fa-solid fa-add" />
                </button>}
            </div>
            {
                (!suppliersLoading && suppliersData) ?
                    <ScreenLayout>
                        <div className="suppliers-list">
                            {
                                filteredSuppliers?.map((supplier, index) => (
                                    <div key={index} className="supplier" onClick={
                                        () => setEditSupplier(supplier)
                                    }>
                                        {
                                            supplier?.name
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </ScreenLayout> :
                    <div className="skeleton-container">
                        <div className="skeleton-list">
                            {loading[language]}
                        </div>
                        <div className="skeleton-list">
                            {loading[language]}
                        </div>
                        <div className="skeleton-list">
                            {loading[language]}
                        </div>
                    </div>

            }
            {
                addSupplier && (
                    <div className="add-supplier-form">
                        <div className="add-supplier-top">

                            <h3 className="add-supplier-heading">
                                {addASupplier[language]}
                            </h3>
                            <button onClick={
                                () => setAddSupplier(false)
                            } className="icon-button">
                                <i className="fa-solid fa-x fa-2x fa-2x" />
                            </button>


                        </div>
                        <div className="supplier-input-div">
                            {
                                errorText && (
                                    <div className="error-text">
                                        {errorText}
                                    </div>
                                )
                            }
                            <input type="text" name="name" className="supplier-input" placeholder={enterName[language]} value={addSupplierData.name} onChange={handleAddSupplierDataChange} required />
                            <input type="email" name="email" className="supplier-input" placeholder={enterTheEmail[language]} value={addSupplierData.email} onChange={handleAddSupplierDataChange} required />
                            <input type="text" name="phone" className="supplier-input" placeholder={enterPhone[language]} value={addSupplierData.phone} onChange={handleAddSupplierDataChange} required />
                        </div>
                        <button className="supplier-save-button" onClick={
                            handleCreateSupplier
                        }>
                            <div />
                            <div>{save[language]}</div>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                )

            }
            {
                editSupplier && (
                    <div className="add-supplier-form">
                        <div className="add-supplier-top">
                            <button className="supplier-top-button" onClick={
                                () => {
                                    setEdit(!edit);
                                    setEditSupplierData({
                                        id: editSupplier.id,
                                        name: editSupplier.name,
                                        email: editSupplier.email,
                                        phone: editSupplier.phone,
                                        merchant: editSupplier.merchantId
                                    });
                                }
                            }>
                                {
                                    edit && (language === '0' ? 'Cancel' : 'Annuler')
                                }
                                {
                                    (user?.role === 'owner' || user?.role === 'editor') && !edit && (language === '0' ? 'Edit' : 'Modifier')
                                }
                            </button>
                            {(user?.role === 'owner' || user?.role === 'editor') && <button className="supplier-top-button" onClick={
                                () => setDeleteConfirmation(true)
                            }>
                                {deleteText[language]}
                            </button>}
                            {
                                deleteConfirmation && (
                                    <div className="delete-confirmation">
                                        <span class="supplier-field">
                                            {deleteSupplierConfirmation[language]}
                                        </span>
                                        <button onClick={
                                            handleDeleteSupplier
                                        } className="yes">
                                            {deleteSupplierLoading ? loading[language] : "Oui"}
                                        </button>
                                        <button onClick={
                                            () => setDeleteConfirmation(false)
                                        } className="no">
                                            Non
                                        </button>
                                    </div>
                                )
                            }
                            <button onClick={
                                () => {
                                    setEditSupplier(null);
                                    setEdit(false);
                                    setEditSupplierData({
                                        name: '',
                                        email: '',
                                        phone: '',
                                        merchant: '',
                                        id: ''
                                    });
                                    setAddSupplierData({
                                        name: '',
                                        email: '',
                                        phone: '',
                                        merchant: sessionStorage.getItem('merchant')
                                    })
                                }
                            } className="icon-button">
                                <i className="fa-solid fa-x fa-2x fa-2x" />
                            </button>

                        </div>
                        <div className="supplier-input-div">
                            {edit ? (
                                <div className="">
                                    <input type="text" name="name" className="supplier-input" placeholder={enterName[language]} value={editSupplierData.name} onChange={handleEditSupplierDataChange} required />
                                    <input type="email" name="email" className="supplier-input" placeholder={enterTheEmail[language]} value={editSupplierData.email} onChange={handleEditSupplierDataChange} required />
                                    <input type="text" name="phone" className="supplier-input" placeholder={enterPhone[language]} value={editSupplierData.phone} onChange={handleEditSupplierDataChange} required />
                                    <button className="supplier-save-button" onClick={
                                        handleEditSupplier
                                    }>
                                        <div />
                                        <div>{save[language]}</div>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </button>
                                </div>
                            ) :
                                (
                                    <>
                                        <span className="supplier-field">
                                            {nameText[language]}: {editSupplier?.name}
                                        </span>
                                        <span className="supplier-field">
                                            {emailText[language]}: {editSupplier?.email}
                                        </span>
                                        <span className="supplier-field">
                                            {phoneText[language]}: {editSupplier?.phone}
                                        </span>
                                    </>)}

                        </div>
                    </div>
                )
            }
        </div>
    );
}

Suppliers.getLayout = (page) =>
    <Layout text="suppliers">
        {page}
    </Layout>;

export default Suppliers;
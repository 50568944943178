
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonApi } from './commonApi';
import {BASE_URL, requestConfig} from "../../util";

export const suppliersApi = commonApi.injectEndpoints({
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        ...requestConfig, // Include the requestConfig in the baseQuery
    }),
    endpoints: (builder) => ({
        getSuppliers: builder.query({
            query: ({merchant, limit, page}) => `merchants/${merchant}/suppliers?limit=${limit}&page=${page}`,
            providesTags: ['Suppliers'],
        }),
        createSupplier: builder.mutation({
            query: (data) => ({
                url: `suppliers`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Suppliers'],
        }),
        deleteSupplier: builder.mutation({
            query: (id) => ({
                url: `suppliers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Suppliers'],
        }),
        getSupplier: builder.query({
            query: (id) => `suppliers/${id}`,
            providesTags: ['Suppliers'],
        }),
        editSupplier: builder.mutation({
            query: (data) => ({
                url: `suppliers`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Suppliers'],
        }),
    }),
});

export const {
    useGetSuppliersQuery,
    useDeleteSupplierMutation,
    useCreateSupplierMutation,
    useEditSupplierMutation,
    useGetSupplierQuery,
} = suppliersApi;